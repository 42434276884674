export default {
    title: "",
    href: 'https://www.facebook.com/funnerix',
    target: '_blank',
    src: null,
    iconSet: "image",
    iconType: "upload",
    iconName: "/img/defaults/facebook.svg",
    style: { backgroundColor: null, color: null, }
}
