<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div class="w-full" :class="itemClasses">
            &nbsp;
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    computed: {
        itemClasses() {
            let classes = [];
            switch (this.theContent.size) {
                case "xs": classes.push('py-3', 'sm:py-1.5'); break;
                case "s":  classes.push('py-4', 'sm:py-2'); break;
                case "m":  classes.push('py-5', 'sm:py-3'); break;
                case "l":  classes.push('py-6', 'sm:py-4'); break;
                case "xl": classes.push('py-7', 'sm:py-5'); break;
                default:  classes.push('px-4', 'sm:py-2'); break;
            }
            return classes;
        },
    }
}
</script>

<style>

</style>
