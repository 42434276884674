import Tiptap from "./Tiptap.vue"
import InlineEdit from "./InlineEdit.vue"
import PartialsWrap from "./PartialsWrap.vue"
export default {
    props: {
        initialTabindex: { type: Number, default: 0 },
        current: { type: Object, required: false, default: ({}) },
        answers: { type: Object, required: false, default: ({}) },
        preview: { type: Boolean, required: true, default: false },
        disabled: { type: Boolean, required: true, default: false },
        element: { type: Object, required: true },
        funnel: { type: Object, required: true },
        modelValue: {
            type: [String,Array],
            default: '',
        },
    },

    data: () => ({
        response: null,
        dragging: false,
        debug: false,
    }),
    mounted() {
        if (window.location.href.includes('debug')) { this.debug = true; }
    },
    components: {
        'funnerix-wrap' : PartialsWrap,
        'funnerix-tiptap' : Tiptap,
        'funnerix-inline-edit' : InlineEdit
    },
    emits: ['update:modelValue', 'performAction', 'iconClicked', 'form:valid', 'form:invalid'],
    computed: {
        elementId() {
            return this.element.id
        },
        primaryColor() {
            return this.funnel.settings && this.funnel.settings.primary ? this.funnel.settings.primary : 'indigo'
        },
        preheaderClasses() {
            let classes = [];
            classes.push(`text-${this.primaryColor}-600`);
            classes.push(`font-semibold`)
            switch (this.theContent.headerSize) {
                case "xs": classes.push('text-lg', 'mb-1'); break;
                case "s":  classes.push('text-lg', 'mb-2'); break;
                case "m":  classes.push('text-lg', 'mb-3'); break;
                case "l":  classes.push('text-lg', 'mb-4'); break;
                case "xl": classes.push('text-lg', 'mb-5'); break;
                case "xxl": classes.push('text-lg', 'mb-6'); break;
                default:  classes.push('text-lg', 'mb-3'); break;
            }
            return classes;
        },
        headerClasses() {
            let classes = [];
            switch (this.theContent.headerSize) {
                case "xs": classes.push('text-xl', 'sm:text-2xl', 'lg:text-3xl', 'mb-1'); break;
                case "s":  classes.push('text-2xl', 'sm:text-3xl', 'lg:text-4xl', 'mb-2'); break;
                case "m":  classes.push('text-3xl', 'sm:text-4xl', 'lg:text-5xl', 'mb-3'); break;
                case "l":  classes.push('text-4xl', 'sm:text-5xl', 'lg:text-6xl', 'mb-4'); break;
                case "xl": classes.push('text-5xl', 'sm:text-6xl', 'lg:text-7xl', 'mb-5'); break;
                case "xxl": classes.push('text-6xl', 'sm:text-7xl', 'lg:text-7xl', 'mb-6'); break;
                default:  classes.push('text-4xl', 'sm:text-5xl', 'lg:text-6xl', 'mb-3'); break;
            }
            return classes;
        },
        sublineClasses() {
            let classes = [];
            switch (this.theContent.headerSize) {
                case "xs": classes.push('text-xs mb-3'); break;
                case "s":  classes.push('text-sm mb-4'); break;
                case "m":  classes.push('text-base mb-5'); break;
                case "l":  classes.push('text-lg mb-6'); break;
                case "xl": classes.push('text-xl mb-7'); break;
                case "xxl": classes.push('text-2xl mb-8'); break;
                default:  classes.push('text-base mb-5'); break;
            }
            return classes;
        },
        theContent() {
            if (this.element && this.element.content && this.element.content.content) {
                return this.element.content.content;
            } else {
                return false;
            }
        },
        allowedToNextPage() {
            // check if the element is required
            if(this.theContent.required && this.theContent.required == true && !this.response) {
                return false;
            }
            // if this button / element has validation property set on, then only go to next, when currently possible
            if(this.theContent.validation && this.theContent.validation == true && this.current && typeof this.current.go_to_next !== 'undefined' && this.current.go_to_next == false) {
                return false;
            }
            return true;
        },
    },
    methods: {
        hasTextContent(html) {
            if (html === null || html === undefined) return false;
            return (html.replace(/<[^>]*>?/gm, '')).length > 0;
        },
        getVarNamesAndIdsOfFunnel() {
            let varNames = Array();
            if(this.answers && typeof this.answers === 'Array') { // it's an array of steps
                this.answers.forEach((step) => {
                    console.info(step, varNames);
                    for (const [key, value] of Object.entries(step.elements)) {
                        console.log(`${key}: ${value}`);
                        varNames.push({
                            id: step.elements[key].id,
                            name: step.elements[key].name,
                            stepIndex: step.index
                        });
                    }
                });
            }
            return varNames;
        },
        replaceContent(source, isHtml = true) {
            this.getVarNamesAndIdsOfFunnel().forEach( (varObj) => {
                console.log(varObj);
                source = this.replaceAnswerVariable(
                    source,
                    varObj.stepIndex,
                    varObj.name,
                    varObj.id
                )
            });
            source = source.replace("%test%", "Funktioniert");
            source = this.replaceGetStrings(source);
            return source;
        },
        replaceGetStrings(str) {
            // replace set by route
            const params = new URLSearchParams(window.location.search)
            for (const param of params) {
                let variableName = '%query:'+param[0]+'%';
                str = str.replace(variableName, param[1]);
            }
            // replace all others, not present right now. general %query:XXX%
            return str.replace(/%query:[a-zA-Z0-9_-]+%/g, '');
        },
        replaceAnswerVariable(source, stepIndex, varName, varId) {
            let value = "";
            if (this.answers[stepIndex] &&
                this.answers[stepIndex].elements &&
                this.answers[stepIndex].elements[varId] &&
                this.answers[stepIndex].elements[varId].answer
            ) {
                if (this.answers[stepIndex].elements[varId].answer != null) {
                    value = this.answers[stepIndex].elements[varId].answer;
                }
            }
            console.log("Replace varName ",varName);
            source = source.replace("%"+varName+"%", value);
            return source;
        },
        textTransformed(html) {
            return html;
        },
        modulesJumpAction(jumpTo, validate = true) {
            if (validate && this.allowedToNextPage) {
                this.$emit('performAction', { jumpTo });
            } else {
                console.log("Not allowed");
                this.$emit('notAllowed');
            }
        },
        nextPage(validate = true) {
            console.log("Wanted to go to the next page...");
            if (validate && this.allowedToNextPage) {
                if (typeof this.theContent.jumpTo !== "undefined") {
                    this.$emit('performAction', { jumpTo: this.theContent.jumpTo });
                } else {
                    this.$emit('performAction', null);
                }
            } else {
                console.log("Not allowed");
                this.$emit('notAllowed');
            }
        },
        // when an input has been performed
        modulesInputCb(event, value, jumpTo = null, validate = true) {
            if (this.element.type == 'form' && event.target && event.target.tagName && event.target.tagName == "INPUT") {
                let allInputs = [...this.$el.querySelectorAll(`[data-funnerix-variable]`)]
                let value = []
                allInputs.forEach(el => {
                    let tmp = {}
                    console.log(el.value);
                    tmp[el.getAttribute('data-funnerix-variable')] = el.value
                    value.push(tmp)
                });
                // is there anything filled?
                let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (allInputs.filter(el => el.value.length > 0).length == allInputs.length) {
                    // is every email field, filled with a proper mail?
                    if (allInputs.filter(el => el.type == 'email').length == allInputs.filter(el => el.value.match(mailformat)).length) {
                        this.$emit('form:valid', true);
                    }
                }
                this.$emit('update:modelValue', value)
            } else if (typeof this.theContent.multiple !== "undefined" && this.theContent.multiple == true && event.target && event.target.tagName && event.target.tagName == "INPUT") {
                let checkedInputs = [...this.$el.querySelectorAll("input")].filter(element => element.checked);
                let values = checkedInputs.map(element => element.value);
                this.$emit('update:modelValue', values)
            } else {
                this.$emit('update:modelValue', value)
            }
            // if the element has link mode
            if (typeof this.theContent.linkMode !== "undefined" && this.theContent.linkMode == true) {
                this.modulesJumpAction(jumpTo, validate)
            }
        }
    },
    directives: {
        focus: {
            mounted(el) {
                el.focus();
            }
        }
    }
}
