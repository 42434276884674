<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div class="max-w-7xl mx-auto text-center" v-if="theContent">
            <div class="text-center" v-if="hasTextContent(theContent.header) || hasTextContent(theContent.subline)">
                <p v-if="hasTextContent(theContent.header)" :class="headerClasses" class="mb-3 tracking-tight font-bold text-gray-900">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.header" :preview="preview" :initialTabindex="initialTabindex" />
                    <div v-else v-html="replaceContent(theContent.header)" class="break-words"/>
                </p>
            </div>
            <label :id="element.id" for="textarea" class="block text-sm font-medium text-gray-700 mb-2" v-if="hasTextContent(theContent.label)">
                <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.label" :preview="preview" :initialTabindex="initialTabindex+1" />
                <div v-else v-html="replaceContent(theContent.label)" class="break-words"/>
            </label>
            <div>
                <textarea
                    :value="modelValue"
                    @input="modulesInputCb($event, $event.target.value)"
                    rows="4"
                    name="textarea"
                    :id="element.id"
                    :placeholder="theContent.placeholder ?? ''"
                    :tabindex="initialTabindex+2"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"></textarea>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ]
}
</script>

<style>

</style>
