<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div v-if="debug" class="font-mono border p-2 text-xs bg-white text-black">Tab Index: {{initialTabindex}} </div>
        <div class="max-w-7xl mx-auto text-center" v-if="theContent">
            <div class="text-center" v-if="hasTextContent(theContent.header) || hasTextContent(theContent.subline)">
                <p v-if="hasTextContent(theContent.header)" :class="headerClasses" class="tracking-tight font-bold">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.header" :preview="preview" :initialTabindex="initialTabindex" />
                    <div v-else v-html="replaceContent(theContent.header)" class="break-words"/>
                </p>
                <p v-if="hasTextContent(theContent.subline)" :class="sublineClasses" class="max-w-xl mx-auto">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.subline" :preview="preview" :initialTabindex="initialTabindex+1" />
                    <div v-else v-html="theContent.subline"/>
                </p>
            </div>
            <label :id="element.id" :for="element.id" class="block text-sm font-medium text-gray-700" v-if="hasTextContent(theContent.label)">
                <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.label" :preview="preview" :initialTabindex="initialTabindex+2" />
                <div v-else v-html="replaceContent(theContent.label)" class="break-words"/>
            </label>
            <div class="mt-1 px-6 flex gap-4">
                <input
                    :value="modelValue"
                    @input="modulesInputCb($event, $event.target.value)"
                    @keyup.enter="nextPage"
                    :type="theContent.type ? theContent.type : 'range'"
                    :min="theContent.min ? theContent.min : 0"
                    :max="theContent.max ? theContent.max : 100"
                    :name="element.id"
                    :id="element.id"
                    :tabindex="initialTabindex+3"
                    class="shadow-sm p-2 block w-full border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                    :class="`accent-${primaryColor}-600 focus:ring-${primaryColor}-500 focus:border-${primaryColor}-500`"
                >
                <span class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200" v-if="theContent.showValue">
                    <svg class="h-1.5 w-1.5" :class="`fill-${primaryColor}-500`" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx="3" cy="3" r="3" />
                    </svg>
                    {{modelValue ?? '-'}}
                </span>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ]
}
</script>

<style>

</style>
