<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div class="max-w-7xl mx-auto" v-if="theContent">
            <fieldset>
                <draggable v-if="theContent && theContent.items"
                    class="-space-y-px"
                    :class="[
                        theContent.type == 'grid' ? 'grid grid-cols-2 lg:grid-cols-2 gap-4' : '',
                        theContent.type == 'list' ? 'grid' : 'grid-cols-1 xl:grid-cols-2 gap-4',
                        theContent.type == 'flex' ? 'flex flex-wrap gap-4' : '',
                    ]"
                    :list="theContent.items"
                    group="multiple-items"
                    @start="dragging = true"
                    @end="dragging = false"
                    ghost-class="moving-element"
                    :animation="150"
                    handle=".dragger"
                    item-key="id"
                >
                    <template #item="{ element, index }">
                        <div
                            class="flex transition-colors single-item relative p-4 rounded-xl"
                            :class="[
                                itemClasses,
                            ]
                            "
                            :style="element.style"
                        >
                            <div :class="[itemIconClasses, preview ? 'cursor-pointer' : 'cursor-default']" @click="$emit('iconClicked', element)">
                                <component v-if="element.iconSet == 'heroicon' && allHeroIcons[element.iconType] && allHeroIcons[element.iconType][element.iconName]"
                                class="w-12 h-12"
                                :is="allHeroIcons[element.iconType][element.iconName]"></component>
                                <span v-else-if="element.iconSet == 'emoji'" class="block w-12 h-12 text-4xl">{{element.iconName}}</span>
                            </div>

                            <funnerix-tiptap :editable="dragging != true && disabled != true" v-if="preview" v-model="element.title" :preview="preview" :editor-class="itemLabelClasses" :initialTabindex="initialTabindex+index" />
                            <div v-else v-html="element.title" :class="itemLabelClasses"  />

                            <div class="single-item-actions absolute right-0 pr-2" v-if="preview">
                                <button type="button" class="dragger mr-1 inline-flex items-center transition-all rounded-full cursor-grab border border-transparent bg-blue-400 p-1 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                                    </svg>
                                </button>
                                <button @click="removeItem(index)" type="button" class="inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </template>
                </draggable>
            </fieldset>

            <div class="item-actions w-full text-center absolute left-0 right-0 bg-transparent -mt-3.5" v-if="preview">
                <button @click="addNewItem" type="button" class="inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                </button>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script setup>
import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
const allHeroIcons = { outline: outlineIcons, solid: solidIcons };
</script>

<script>
import modulesMixin from "../modulesMixin";
import draggable from "vuedraggable";
import single from "../../../conf/items/list"
export default {
    mixins: [ modulesMixin ],
    components: {
        draggable,
    },
    methods: {
        addNewItem() {
            let n = this.theContent.items.length+1;
            let newItem = JSON.parse(JSON.stringify(single));
            newItem.title = "<strong>Neuer Listenpunkt "+n+"</strong> Gib hier deinen Text ein";
            this.theContent.items.push(newItem);
        },
        removeItem(index) {
            this.theContent.items.splice(index, 1); // 1 means number of elements to remove
        }
    },
    computed: {
        itemClasses() {
            let classes = [];
            classes.push('flex');
            return classes;
        },
        itemLabelClasses() {
            let classes = [];
            if (this.theContent.type == "list") {
                classes.push(`order-2`);
            } else {
                classes.push(`ml-auto`, `mr-auto`, `w-full`);
                if (this.theContent.iconPlace == 'bottom') {
                    classes.push(`order-2`);
                } else {
                    classes.push(`order-1`);
                }
            }
            return classes;
        },
        itemIconClasses() {
            let classes = [];
            if (this.theContent.type == "list") {
                classes.push(`order-1`, 'mr-4');
            } else {
                classes.push(`ml-auto`, `mr-auto`);
                if (this.theContent.iconPlace == 'bottom') {
                    classes.push(`order-1`);
                } else {
                    classes.push(`order-2`);
                }
            }
            if (this.theContent.invert == true) {
                classes.push(`text-white`);
            } else {
                classes.push(`text-${this.primaryColor}-600`);
            }
            return classes;
        }
    }
}
</script>

<style>

</style>
