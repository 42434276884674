<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div class="max-w-7xl mx-auto text-center" v-if="theContent">
            <div class="text-center" v-if="hasTextContent(theContent.header) || hasTextContent(theContent.subline)">
                <p v-if="hasTextContent(theContent.header)" :class="headerClasses" class="tracking-tight font-bold">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.header" :preview="preview" :initialTabindex="initialTabindex" />
                    <div v-else v-html="replaceContent(theContent.header)" class="break-words"/>
                </p>
                <p v-if="hasTextContent(theContent.subline)" :class="sublineClasses" class="max-w-xl mx-auto">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.subline" :preview="preview" :initialTabindex="initialTabindex+1" />
                    <div v-else v-html="theContent.subline"/>
                </p>
            </div>
            <fieldset class="mx-3">
                <draggable v-if="theContent && theContent.items"
                    class="rounded-md"
                    :class="[
                        theContent.type === 'grid' ? 'grid grid-cols-2 gap-4 -space-y-px' : '',
                        theContent.type === 'list' ? 'flex flex-col space-y-4' : '',
                        theContent.type === 'flex' ? 'flex gap-4 -space-y-px' : '',
                    ]"
                    :list="theContent.items"
                    group="imagegrid-items"
                    ghost-class="moving-element"
                    :animation="150"
                    handle=".dragger"
                    item-key="id"
                >
                    <template #item="{ element, index }">
                        <div
                            @click="select($event, element, index)"
                            class="transition-all single-item rounded-xl relative shadow p-4 cursor-pointer hover:shadow-sm border border-transparent"
                            :class="itemClasses"
                            :style="element.style"
                        >
                            <!-- <funnerix-inline-edit :editable="!disabled" v-model="element.title" :preview="preview" class="text-center" :class="itemLabelClasses" :tabindex="initialTabindex+1+index">
                                {{element.title}}
                            </funnerix-inline-edit> -->
                            <funnerix-tiptap class="cursor-text" :editable="!disabled" :class="itemLabelClasses" v-if="preview" v-model="element.title" :preview="preview" :initialTabindex="initialTabindex+1+index" />
                            <div v-else v-html="replaceContent(element.title)" class="break-words" :class="itemLabelClasses"/>

                            <div :class="[itemIconClasses, preview ? 'cursor-pointer' : 'cursor-default']" @click="$emit('iconClicked', element)">
                                <component v-if="element.iconSet === 'heroicon' && allHeroIcons[element.iconType] && allHeroIcons[element.iconType][element.iconName]"
                                class="w-12 h-12"
                                :is="allHeroIcons[element.iconType][element.iconName]"></component>
                                <span v-else-if="element.iconSet === 'emoji'" class="block w-12 h-12 text-4xl">{{element.iconName}}</span>
                                <div v-else-if="element.iconSet === 'image'" class="block w-12 h-12 text-4xl">
                                    <img :src="element.iconName" :alt="element.title">
                                </div>
                            </div>

                            <div class="single-item-actions absolute top-0 right-0 pt-2 pr-2" v-if="preview">
                                <button type="button" class="dragger mr-1 inline-flex items-center transition-all rounded-full cursor-grab border border-transparent bg-blue-400 p-1 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                                    </svg>
                                </button>
                                <button @click="removeItem(index)" type="button" class="inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </template>
                </draggable>
            </fieldset>

            <div class="item-actions w-full text-center absolute left-0 right-0 bg-transparent -mt-3.5" v-if="preview">
                <button @click="addNewItem" type="button" class="inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                </button>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script setup>
import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
const allHeroIcons = { outline: outlineIcons, solid: solidIcons };
</script>

<script>
import modulesMixin from "../modulesMixin";
import draggable from "vuedraggable";
import single from "../../../conf/items/imagegrid"
export default {
    mixins: [ modulesMixin ],
    components: {
        draggable
    },
    methods: {
        select(event, item, index) {
            this.modulesInputCb(event, item.title, item.jumpTo)
        },
        addNewItem() {
            let n = this.theContent.items.length+1;
            let newItem = JSON.parse(JSON.stringify(single));
            newItem.title = "Antwort "+n;
            this.theContent.items.push(newItem);
        },
        removeItem(index) {
            this.theContent.items.splice(index, 1); // 1 means number of elements to remove
        }
    },
    computed: {
        itemClasses() {
            let classes = [];
            if (this.theContent.invert === true) {
                classes.push(`bg-${this.primaryColor}-600 hover:bg-${this.primaryColor}-500`, `text-white`);
            } else {
                classes.push(`hover:text-${this.primaryColor}-500`, `hover:border-${this.primaryColor}-500`);
            }
            if (this.theContent.type === 'list') {
                classes.push('flex', 'justify-start')
            } else {
                classes.push('flex', 'flex-col')
            }
            if (!this.preview) {
                classes.push(`hover:scale-105 hover:shadow-lg`); // hover effect
            }
            return classes;
        },
        itemLabelClasses() {
            let classes = [];
            if (this.theContent.type === "list") {
                classes.push(`order-2`);
            } else {
                classes.push(`ml-auto`, `mr-auto`, `w-full`);
                if (this.theContent.iconPlace === 'bottom') {
                    classes.push(`order-2`);
                } else {
                    classes.push(`order-1`);
                }
            }
            return classes;
        },
        itemIconClasses() {
            let classes = [];
            if (this.theContent.type === "list") {
                classes.push(`order-1`, 'mr-4');
            } else {
                classes.push(`ml-auto`, `mr-auto`, `mb-2`);
                if (this.theContent.iconPlace === 'bottom') {
                    classes.push(`order-1`);
                } else {
                    classes.push(`order-2`);
                }
            }
            if (this.theContent.invert === true) {
                classes.push(`text-white`);
            } else {
                classes.push(`text-${this.primaryColor}-600`);
            }
            return classes;
        }
    }
}
</script>

<style>

</style>
