<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div v-if="theContent">
            <div class="text-center">
                <h2 v-if="hasTextContent(theContent.preheader)" :class="preheaderClasses">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.preheader" :editor-class="preheaderClasses" :preview="preview" :initialTabindex="initialTabindex" />
                    <div v-else v-html="theContent.preheader" />
                </h2>
                <p v-if="hasTextContent(theContent.header)" :class="headerClasses" class="mb-3 tracking-tight font-bold text-gray-900">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.header" :editor-class="headerClasses" :preview="preview" :initialTabindex="initialTabindex+1" />
                    <div v-else v-html="replaceContent(theContent.header)" class="break-words" />
                </p>
                <p v-if="hasTextContent(theContent.subline)" :class="sublineClasses" class="max-w-xl mt-5 mx-auto text-gray-500">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.subline" :editor-class="sublineClasses" :preview="preview" :initialTabindex="initialTabindex+2" />
                    <div v-else v-html="theContent.subline" />
                </p>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
}
</script>

<style>

</style>
