<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div v-if="debug" class="font-mono border p-2 text-xs bg-white text-black">Tab Index: {{initialTabindex}} </div>
        <div class="max-w-7xl mx-auto text-center" v-if="theContent">
            <div class="text-center" v-if="hasTextContent(theContent.header)">
                <p :class="headerClasses" class="tracking-tight font-bold">
                    <funnerix-tiptap v-if="preview" v-model="theContent.header" :editable="!disabled" :preview="preview" :initialTabindex="initialTabindex" />
                    <div v-else v-html="replaceContent(theContent.header)" class="break-words" />
                </p>
            </div>
            <fieldset >
                <draggable v-if="theContent && theContent.items"
                    class="rounded-md -space-y-px"
                    :list="theContent.items"
                    group="multiple-items"
                    ghost-class="moving-element"
                    :animation="150"
                    handle=".dragger"
                    item-key="id"
                >
                    <template #item="{ element, index }">
                        <div
                            class="transition-colors h-16 single-item relative border p-2 flex items-center cursor-pointer focus:outline-none"
                            :class="[
                                itemClasses,
                                index == 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                index == theContent.items.length-1 ? 'rounded-bl-md rounded-br-md' : '',
                            ]"
                            :style="element.style"
                        >
                            <template v-if="element.type == 'email' || element.type == 'text' || element.type == 'phone'">
                                <div :class="[itemIconClasses, preview ? 'cursor-pointer' : 'cursor-default']" @click.stop="$emit('iconClicked', element)" class="flex items-center">
                                    <component
                                    v-if="element.iconSet == 'heroicon' && allHeroIcons[element.iconType] && allHeroIcons[element.iconType][element.iconName]"
                                    :is="allHeroIcons[element.iconType][element.iconName]"
                                    class="w-12 h-12 self-center" />
                                    <span v-else-if="element.iconSet == 'emoji'" class="flex self-center items-center w-12 h-12 text-4xl">{{element.iconName}}</span>
                                </div>
                            </template>
                            <template v-else-if="element.type == 'accept'">
                                <label class="w-12 h-12 cursor-pointer flex justify-center items-center mr-1">
                                    <input :type="theContent.multiple ? 'checkbox' : 'radio'"
                                        ref="input"
                                        :name="elementId"
                                        :value="element.title"
                                        :class="`text-${primaryColor}-600 focus:ring-${primaryColor}-500`"
                                        class="h-6 w-6 mt-0.5 shrink-0 border-gray-300 self-center"
                                        :tabindex="initialTabindex+index"
                                        @input="modulesInputCb($event, element.title, element.jumpTo)"
                                    >
                                </label>
                            </template>
                            <template v-else-if="element.type == 'checkbox'">
                                <label class="w-12 h-12 cursor-pointer flex justify-center items-center mr-1">
                                    <input :type="element.type"
                                        ref="input"
                                        :name="elementId"
                                        :value="element.title"
                                        :class="`text-${primaryColor}-600 focus:ring-${primaryColor}-500`"
                                        class="h-6 w-6 mt-0.5 shrink-0 border-gray-300 self-center"
                                        :tabindex="initialTabindex+index"
                                        @input="modulesInputCb($event, element.title, element.jumpTo)"
                                    >
                                </label>
                            </template>
                            <span :class="itemLabelClasses">
                                <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" -->
                                <span id="privacy-setting-0-label" class="block text-sm font-medium text-left">
                                    <funnerix-inline-edit v-if="preview" :editable="!disabled" v-model="element.placeholder" :preview="preview" :tabindex="initialTabindex+10+index">
                                        {{element.placeholder}}
                                    </funnerix-inline-edit>
                                    <span v-else-if="element.type == 'accept'">{{element.placeholder}}</span>
                                    <input v-else
                                        @input="modulesInputCb($event, $event.target.value, element.jumpTo)"
                                        @keyup.enter="nextPage"
                                        :data-funnerix-variable="element.variable"
                                        :type="element.type == 'email' ? 'email' : 'text'"
                                        :name="element.id"
                                        :id="element.id"
                                        :tabindex="initialTabindex+1+index"
                                        class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:cursor-not-allowed disabled:shadow-none invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                                        :placeholder="element.placeholder">
                                </span>
                            </span>
                            <div class="single-item-actions absolute right-0 pr-2" v-if="preview">
                                <button type="button" class="dragger mr-1 inline-flex items-center transition-all rounded-full cursor-grab border border-transparent bg-blue-400 p-1 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                                    </svg>
                                </button>
                                <!-- <button @click="configItem(item, index)" type="button" class="mr-1 inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                </button> -->
                                <button @click="removeItem(index)" type="button" class="inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </template>
                </draggable>
            </fieldset>

            <div class="item-actions w-full text-center absolute left-0 right-0 bg-transparent -mt-3.5" v-if="preview">
                <button @click="addNewItem('text', $event)" type="button" class="inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                </button>
            </div>

            <div class="text-center mt-4" v-if="hasTextContent(theContent.subline)">
                <p :class="sublineClasses" class="max-w-xl mx-auto">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.subline" :editor-class="sublineClasses" :preview="preview" :initialTabindex="initialTabindex+1" />
                    <div v-else v-html="theContent.subline"/>
                </p>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
import draggable from "vuedraggable";
import single from "../../../conf/items/form"
import str from "../../../helper/str.js";
export default {
    mixins: [ modulesMixin ],
    components: {
        draggable,
    },
    methods: {
        addNewItem(type = 'text', event) {
            let n = this.theContent.items.length+1;
            let newItem = JSON.parse(JSON.stringify(single));
            newItem.variable = 'field-'+str.randStr(5);
            newItem.placeholder = "Neues Feld "+n;
            newItem.type = type;
            this.theContent.items.push(newItem);
        },
        removeItem(index) {
            this.theContent.items.splice(index, 1); // 1 means number of elements to remove
        },
        configItem(item, index){

        }
    },
    computed: {
        itemClasses() {
            let classes = [];
            if (this.theContent.invert == true) {
                classes.push(`bg-${this.primaryColor}-600 hover:bg-${this.primaryColor}-500`, `text-white`);
            } else {
                classes.push(`hover:text-${this.primaryColor}-500`, `hover:border-${this.primaryColor}-500`);
            }
            return classes;
        },
        itemLabelClasses() {
            let classes = [];
            classes.push(`w-full`);
            classes.push(`pl-4`)
            return classes;
        },
        itemIconClasses() {
            let classes = [];
            if (this.theContent.invert == true) {
                classes.push(`text-white`);
            } else {
                classes.push(`text-${this.primaryColor}-600`);
            }
            return classes;
        }
    }
}
</script>

<style>

</style>
