<template>
    <div :class="wrapperClasses" @click="clickCb" class="transition-all">
        <img :src="funnel.head_logo_url" :class="imgClasses" v-if="funnel.head_logo_url" />
    </div>
</template>

<script>
export default {
    props: {
        preview: { type: Boolean, required: true, default: false },
        funnel: { type: Object, required: true },
    },
    emits: ['clicked'],
    methods: {
        clickCb() {
            if (this.preview) {
                this.$emit('clicked')
            }
        }
    },
    computed: {
        wrapperClasses() {
            let classes = []
            classes.push('pl-2 pr-2')
            if(this.preview) {
                classes.push('hover:border-indigo-500 border border-transparent cursor-pointer')
            }
            return classes
        },
        imgClasses() {
            let classes = []
            switch (this.funnel.settings.logoSize) {
                case 'xs': classes.push(`h-6 mb-2 mt-2`); break;
                case 's':  classes.push(`h-8 mb-2 mt-2`); break;
                case 'm':  classes.push(`h-10 mb-3 mt-3`); break;
                case 'l':  classes.push(`h-12 mb-3 mt-3`); break;
                case 'xl': classes.push(`h-14 mb-3 mt-3`); break;
                default: classes.push(`h-10 mb-3 mt-3`); break;
            }

            switch (this.funnel.settings.logoAlign) {
                case 'left': classes.push(`mr-auto`); break;
                case 'center':  classes.push(`ml-auto mr-auto`); break;
                case 'right':  classes.push(`ml-auto`); break;
                default: classes.push(`mr-auto`); break;
            }

            return classes
        }
    }
}
</script>

<style>

</style>
