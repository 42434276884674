<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div v-if="theContent">
            <div class="flex items-center justify-center max-w-xl mx-auto">
                <component :is="allHeroIcons['solid'][theContent.iconName]" v-for="n in theContent.max" :key="element.id+'star'+n"
                    @mouseover="currentHover = n"
                    @mouseout="currentHover = null"
                    :class="starClass(n)"
                    :style="starStyle(n)"
                    @click="selectStar($event, element, n)"
                    class="cursor-pointer flex-shrink-0">
                </component>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script setup>
import * as outlineIcons  from '@heroicons/vue/outline'
import * as solidIcons  from '@heroicons/vue/solid'
const allHeroIcons = { outline: outlineIcons, solid: solidIcons };
</script>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    data: () => ({
        currentHover: null
    }),
    computed: {
        currentValue() {
            if (this.currentHover) {
                return this.currentHover;
            }
            return this.theContent.start
        }
    },
    methods: {
        selectStar(event, element, n) {
            this.$emit('iconClicked', this.theContent, [1])
            this.modulesInputCb(event, n, this.theContent.jumpTo)
        },
        starStyle(n) {
            let val = null;
            if (this.currentHover != null) {
                val = this.currentHover;
            } else {
                val = this.currentValue
            }
            return (val >= n ? this.element.content.styleStar : this.element.content.styleStarSelected);
        },
        starClass(n) {
            let classes = [];
            switch (this.theContent.size) {
                case "xs": classes.push('h-4 w-4'); break;
                case "s":  classes.push('h-6 w-6'); break;
                case "m":  classes.push('h-7 w-7'); break;
                case "l":  classes.push('h-8 w-8'); break;
                case "xl": classes.push('h-12 w-12'); break;
                case "xxl": classes.push('h-16 w-16'); break;
                default:  classes.push('h-6 w-6'); break;
            }
            let val = null;
            if (this.currentHover != null) {
                val = this.currentHover;
            } else {
                val = this.currentValue
            }
            classes.push (val >= n ? `text-${this.primaryColor}-400` : `text-gray-300`);
            return classes;
        }
    }
}
</script>

<style>

</style>
