<template>
    <div class="circle-loader">
        <div class="checkmark draw"></div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

</style>
