<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div class="max-w-7xl mx-auto text-center" v-if="theContent">
            <div class="text-center" v-if="hasTextContent(theContent.header) || hasTextContent(theContent.subline)">
                <p v-if="hasTextContent(theContent.header)" :class="headerClasses" class="tracking-tight font-bold">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.header" :preview="preview" :initialTabindex="initialTabindex" />
                    <div v-else v-html="replaceContent(theContent.header)" class="break-words"/>
                </p>
                <p v-if="hasTextContent(theContent.subline)" :class="sublineClasses" class="max-w-xl mx-auto">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.subline" :preview="preview" :initialTabindex="initialTabindex+1" />
                    <div v-else v-html="theContent.subline"/>
                </p>
            </div>
            <div class="sm:border-t sm:border-gray-200 sm:pt-5" v-if="preview">
                <div class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto h-12 w-12 text-gray-300">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                    </svg>
                    <span class="mt-2 block text-sm font-semibold text-gray-300">
                        {{ $t('Uploading files is not possible in preview mode') }}
                    </span>
                </div>
            </div>
            <div class="sm:border-t sm:border-gray-200 sm:pt-5" v-bind="getRootProps()" v-else-if="!uploaded">
                <div class="w-full flex justify-center px-6 pt-5 pb-6 rounded-md"
                    :class="{ 'border-2 border-purple-300' : isDragActive, 'border-2 border-gray-300 border-dashed' : !isDragActive }">
                    <div class="space-y-1 text-center">
                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <div class="flex text-sm text-gray-600">
                            <div class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                <span>Upload file</span>
                                <input id="file-upload"
                                v-bind="getInputProps()"
                                name="file-upload" type="file" class="sr-only">
                            </div>
                            <p class="pl-1" v-if="isDragActive">und loslassen...</p>
                            <p class="pl-1" v-else>oder ziehen und loslassen</p>
                        </div>
                        <p class="text-xs text-gray-500">PNG, JPG, GIF - 4MB</p>
                    </div>
                </div>
            </div>
            <div v-else>
                <CheckAnimated></CheckAnimated>
                Erfolgreich hochgeladen!
            </div>
        </div>
    </funnerix-wrap>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import CheckAnimated from "../../CheckAnimated.vue"
import modulesMixin from "../modulesMixin";
import draggable from "vuedraggable";
import single from "../../../conf/items/multiple"
export default {
    mixins: [ modulesMixin ],
    components: {
        draggable,
        CheckAnimated,
    },
    created() {
        const { getRootProps, getInputProps, isDragActive, ...rest } = useDropzone({ onDrop: this.onDrop });
        this.getRootProps = getRootProps;
        this.isDragActive = isDragActive;
        this.getInputProps = getInputProps;
    },
    data: () => ({
        uploaded: false,
        reply: null,
        isDragActive: null,
        getRootProps: null,
        getInputProps: null,
    }),
    methods: {
        onDrop(acceptFiles, rejectReasons) {
            console.log(acceptFiles);
            console.log(rejectReasons);
            this.saveFile(acceptFiles);
        },
        async saveFile(files) {
            const formData = new FormData(); // pass data as a form
            for (var x = 0; x < files.length; x++) {
                // append files as array to the form, feel free to change the array name
                formData.append("file", files[x]);
            }
            let url = '/api/funnels/'+this.funnel.id+'/fileUpload';
            let event = new Event('input', { tagName: 'none', bubbles: true, cancelable: true });
            // post the formData to your backend where storage is processed. In the backend, you will need to loop through the array and save each file through the loop.
            try {
                let response = await axios.post(url, formData, { headers: { "Content-Type": "multipart/form-data", }});
                if (response.data && response.data.location) {
                    this.uploaded = true;
                    console.log("Uploaded successfully **************** ");
                    this.modulesInputCb(event, response.data.location);
                }
            } catch ( err ) {
                console.error(err);
            }
        },
        uploadCb(event) {
            this.modulesInputCb(event, event.target.value);
        },
        addNewItem() {
            let n = this.theContent.items.length+1;
            let newItem = JSON.parse(JSON.stringify(single));
            newItem.title = "Antwort "+n;
            this.theContent.items.push(newItem);
        },
        removeItem(index) {
            this.theContent.items.splice(index, 1); // 1 means number of elements to remove
        }
    },
    computed: {
        itemClasses() {
            let classes = [];
            if (this.theContent.invert == true) {
                classes.push(`bg-${this.primaryColor}-600 hover:bg-${this.primaryColor}-500`, `text-white`);
            } else {
                classes.push(`hover:text-${this.primaryColor}-500`, `hover:border-${this.primaryColor}-500`);
            }
            return classes;
        },
        itemLabelClasses() {
            let classes = [];
            classes.push(`w-full`);
            classes.push(`pl-4`)
            return classes;
        },
        itemIconClasses() {
            let classes = [];
            if (this.theContent.invert == true) {
                classes.push(`text-white`);
            } else {
                classes.push(`text-${this.primaryColor}-600`);
            }
            return classes;
        }
    }
}
</script>

<style>

</style>
