<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div class="text-center w-full" v-if="theContent">
            <button
                @click="modulesJumpAction(theContent.jumpTo)"
                type="button"
                :disabled="!allowedToNextPage"
                class="transition-all inline-flex disabled:cursor-not-allowed items-center border border-transparent font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                :class="itemClasses"
                :style="element.content.styleButton"
            >
                <funnerix-tiptap :editable="dragging != true && disabled != true" v-if="preview" v-model="theContent.text" :preview="preview" :editor-class="textClass" :initialTabindex="initialTabindex" />
                <div v-else v-html="theContent.text" :class="textClass"  />
            </button>
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    computed: {
        textClass() {
            let classes = []
            switch (this.theContent.size) {
                case "xs": classes.push('text-xs'); break;
                case "s":  classes.push('text-sm'); break;
                case "m":  classes.push('text-sm'); break;
                case "l":  classes.push('text-base'); break;
                case "xl": classes.push('text-xl'); break;
                case "xxl": classes.push('text-2xl'); break;
                default:  classes.push('text-sm'); break;
            }
            return classes
        },
        itemClasses() {
            let classes = [];
            classes.push(this.textClass);
            if (this.theContent.animate) {
                if (this.theContent.animateOnHover == true) {
                    classes.push('hover:'+this.theContent.animate);
                } else {
                    classes.push(this.theContent.animate);
                }
            }
            if (this.theContent.size == 'xs') { classes.push('rounded'); }
            else { classes.push('rounded-md'); }
            switch (this.theContent.size) {
                case "xs": classes.push('px-2.5', 'py-1.5'); break;
                case "s":  classes.push('px-3', 'py-2'); break;
                case "m":  classes.push('px-4', 'py-2'); break;
                case "l":  classes.push('px-4', 'py-2'); break;
                case "xl": classes.push('px-6', 'py-3'); break;
                case "xxl": classes.push('px-8', 'py-4'); break;
                default:  classes.push('px-4', 'py-2'); break;
            }
            if (!this.preview) {
                classes.push(`hover:scale-105 hover:shadow-lg`); // hover effect
            }
            if (this.theContent.invert == true) {
                classes.push(
                    `bg-${this.primaryColor}-600`,
                    `disabled:bg-${this.primaryColor}-300`,
                    `hover:disabled:bg-${this.primaryColor}-500`,
                    `invalid:border-red-500`,
                    `border-white`,
                    `hover:bg-${this.primaryColor}-700`,
                    `text-white`
                );
            } else {
                classes.push(
                    `bg-white`,
                    `border-${this.primaryColor}-600`,
                    `text-${this.primaryColor}-600`,
                    `hover:text-${this.primaryColor}-700`,
                    `hover:border-${this.primaryColor}-700`
                );
            }
            return classes;
        },
    }
}
</script>

<style>

</style>
