<template>
    <div :class="editorClass">
        <editor-content :editor="editor" v-if="preview" :tabindex="initialTabindex" />
        <template v-else>{{modelValue}}</template>
        <bubble-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
            <button class="mr-1 inline-flex items-center rounded-full border border-transparent bg-gray-600 dark: px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="editor.chain().focus().toggleBold().run()" :class="{ 'bg-indigo-500 text-white': editor.isActive('bold') }">
                <strong>b</strong>
            </button>
            <button class="mr-1 inline-flex items-center rounded-full border border-transparent bg-gray-600 dark: px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="editor.chain().focus().toggleItalic().run()" :class="{ 'bg-indigo-500 text-white': editor.isActive('italic') }">
                <em>i</em>
            </button>
            <button class="mr-1 inline-flex items-center rounded-full border border-transparent bg-gray-600 dark: px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'bg-indigo-500 text-white': editor.isActive('underline') }">
                <u>u</u>
            </button>
            <button class="mr-1 inline-flex items-center rounded-full border border-transparent bg-gray-600 dark: px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            @click="editor.chain().focus().toggleStrike().run()" :class="{ 'bg-indigo-500 text-white': editor.isActive('strike') }">
                <span class="line-through">s</span>
            </button>
        </bubble-menu>
    </div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-3'
import Underline from '@tiptap/extension-underline'

export default {
  components: {
    EditorContent, BubbleMenu
  },

  props: {
    preview: { type: Boolean, default: false, },
    editable: { type: Boolean, default: true, },
    editorClass: { type: Object, default: ({})},
    initialTabindex: { type: Number, default: 0 },
    modelValue: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      editable: this.editable,
      extensions: [
        StarterKit,
        Underline,
      ],
      content: this.modelValue,
      onUpdate: () => {
        // HTML
        this.$emit('update:modelValue', this.editor.getHTML())

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<style>
.ProseMirror:focus {
    outline: none;
}
</style>
