<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div :class="imageWrapClasses" v-if="theContent">
            <img :class="imageClasses" :src="theContent.src" v-if="theContent.src" />
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    computed: {
        imageClasses() {
            let classes = []
            classes.push(`max-w-full aspect-video mx-auto`);
            return classes
        },
        imageWrapClasses() {
            let classes = []
            if(this.theContent.fullWidth != true) {
                switch (this.theContent.size) {
                    case "xs": classes.push('mx-24 my-2'); break;
                    case "s":  classes.push('mx-14 my-3'); break;
                    case "m":  classes.push('mx-10 my-4'); break;
                    case "l":  classes.push('mx-5 my-5'); break;
                    case "xl": classes.push('mx-4 my-6'); break;
                    default:  classes.push('mx-10 my-4'); break;
                }
            }
            return classes
        }
    }
}
</script>

<style>

</style>
