<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div v-if="debug" class="font-mono border p-2 text-xs bg-white text-black">Tab Index: {{initialTabindex}} </div>
        <div class="max-w-7xl mx-auto text-center" v-if="theContent">
            <div class="text-center" v-if="hasTextContent(theContent.header) || hasTextContent(theContent.subline)">
                <p v-if="hasTextContent(theContent.header)" :class="headerClasses" class="tracking-tight font-bold">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.header" :preview="preview" :initialTabindex="initialTabindex" />
                    <div v-else v-html="replaceContent(theContent.header)" class="break-words"/>
                </p>
                <p v-if="hasTextContent(theContent.subline)" :class="sublineClasses" class="max-w-xl mx-auto">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.subline" :preview="preview" :initialTabindex="initialTabindex+1" />
                    <div v-else v-html="theContent.subline"/>
                </p>
            </div>
            <fieldset class="mx-3">
                <draggable v-if="theContent && theContent.items"
                    class="rounded-md -space-y-px"
                    :class="[
                        theContent.type == 'grid' ? 'grid grid-cols-2 gap-4' : '',
                        theContent.type == 'list' ? '' : '',
                        theContent.type == 'flex' ? 'flex flex-wrap gap-4' : '',
                    ]"
                    :list="theContent.items"
                    group="multiple-items"
                    ghost-class="moving-element"
                    :animation="150"
                    handle=".dragger"
                    item-key="id"
                >
                    <template #item="{ element, index }">
                        <label
                            class="transition-colors single-item relative border p-4 flex cursor-pointer focus:outline-none flex-wrap"
                            :class="[
                                itemClasses,
                                theContent.type == 'list' ? (index == 0 ? 'rounded-tl-md rounded-tr-md' : (index == theContent.items.length-1 ? 'rounded-bl-md rounded-br-md' : '')) :
                                theContent.type == 'grid' || theContent.type == 'flex' ? 'rounded-md' : ''
                            ]
                            "
                            :style="element.style"
                        >
                            <input :type="theContent.multiple ? 'checkbox' : 'radio'"
                                ref="input"
                                :name="elementId"
                                :value="element.title"
                                :class="`text-${primaryColor}-600 focus:ring-${primaryColor}-500`"
                                class="h-4 w-4 mt-0.5 cursor-pointer shrink-0 border-gray-300 "
                                aria-labelledby="privacy-setting-0-label"
                                aria-describedby="privacy-setting-0-description"
                                :tabindex="preview ? false : initialTabindex+2"
                                @input="modulesInputCb($event, element.title, element.jumpTo)"
                            >
                            <span class="ml-3 flex flex-col flex-1">
                                <!-- Checked: "text-indigo-900", Not Checked: "text-gray-900" -->
                                <span id="privacy-setting-0-label" class="block text-sm font-medium text-left">
                                    <funnerix-inline-edit :editable="!disabled" v-model="element.title" :preview="preview" :initialTabindex="initialTabindex+2+index">
                                        {{element.title}}
                                    </funnerix-inline-edit>
                                    <div v-if="debug" class="font-mono border p-2 text-xs bg-white text-black">Tab Index: {{initialTabindex}} + 2 + {{index}} = {{initialTabindex+2+index}}</div>
                                </span>
                            </span>
                            <div class="single-item-actions absolute right-0 pr-2" v-if="preview">
                                <button type="button" class="dragger mr-1 inline-flex items-center transition-all rounded-full cursor-grab border border-transparent bg-blue-400 p-1 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                                    </svg>
                                </button>
                                <button @click="removeItem(index)" type="button" class="inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </label>
                    </template>
                </draggable>
            </fieldset>

            <div class="item-actions w-full text-center absolute left-0 right-0 bg-transparent -mt-3.5" v-if="preview">
                <button @click="addNewItem" type="button" class="inline-flex items-center transition-all rounded-full border border-transparent bg-gray-400 p-1 text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
                    <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                </button>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
import draggable from "vuedraggable";
import single from "../../../conf/items/multiple"
export default {
    mixins: [ modulesMixin ],
    components: {
        draggable,
    },
    methods: {
        addNewItem() {
            let n = this.theContent.items.length+1;
            let newItem = JSON.parse(JSON.stringify(single));
            newItem.title = "Antwort "+n;
            this.theContent.items.push(newItem);
        },
        removeItem(index) {
            this.theContent.items.splice(index, 1); // 1 means number of elements to remove
        }
    },
    computed: {
        itemClasses() {
            let classes = [];
            if (this.theContent.invert == true) {
                classes.push(`bg-${this.primaryColor}-600 hover:bg-${this.primaryColor}-500`, `text-white`);
            } else {
                classes.push(`hover:text-${this.primaryColor}-500`, `hover:border-${this.primaryColor}-500`);
            }
            return classes;
        },
    }
}
</script>

<style>

</style>
