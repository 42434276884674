<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div class="" v-if="theContent">
            <div class="max-w-7xl mx-auto" :class="`text-${theContent.align}`">
                <div
                    class="max-w-prose mx-auto lg:max-w-lg lg:ml-auto"
                    :class="itemClasses">
                    <textarea v-if="preview" rows="6" name="html" id="html" class="block w-full rounded-md font-mono border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" v-model="theContent.html" :tabindex="initialTabindex" />
                    <div v-else v-html="theContent.html" />
                </div>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    computed: {
        itemClasses() {
            let classes = [];
            switch (this.theContent.size) {
                case "xs": classes.push('text-xs'); break;
                case "s":  classes.push('text-md'); break;
                case "m":  classes.push('text-lg'); break;
                case "l":  classes.push('text-xl'); break;
                case "xl": classes.push('text-2xl'); break;
                default:  classes.push('text-lg'); break;
            }
            switch (this.theContent.align) {
                case "center": classes.push('text-center'); break;
                case "right": classes.push('text-right'); break;
                default: classes.push('text-left'); break;
            }
            return classes;
        },
    }
}
</script>

<style>

</style>
