<template>
    <div :class="classes" :style="styleObj" class="transition-all partials-wrap">
        <div class="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        element: Object,
        funnel: Object
    },
    computed: {
        customBgColor1() {
            return this.funnel.settings.color_bg_custom1 ?? null;
        },
        customColor1() {
            return this.funnel.settings.color_text_custom1 ?? this.customBgColor1;
        },
        classes() {
            let classes = [];
            classes.push(`bg-white`)
            classes.push(`px-2 sm:px-4`)
            classes.push(`funnerix-module funnerix-module-${this.element.type}`)

            if(this.theContent.paddingTop) {
                switch (this.theContent.paddingTop) {
                    case "xs": classes.push('pt-1', 'sm:pt-3'); break;
                    case "s":  classes.push('pt-2', 'sm:pt-6'); break;
                    case "m":  classes.push('pt-3', 'sm:pt-8'); break;
                    case "l":  classes.push('pt-6', 'sm:pt-10'); break;
                    case "xl": classes.push('pt-8', 'sm:pt-14'); break;
                    case "xxl":classes.push('pt-12', 'sm:pt-18'); break;
                    default: classes.push('pt-4', 'sm:pt-6'); break;
                }
            }

            if(this.theContent.paddingBottom) {
                switch (this.theContent.paddingBottom) {
                    case "xs": classes.push('pb-1', 'sm:pb-3'); break;
                    case "s":  classes.push('pb-2', 'sm:pb-6'); break;
                    case "m":  classes.push('pb-3', 'sm:pb-8'); break;
                    case "l":  classes.push('pb-6', 'sm:pb-10'); break;
                    case "xl": classes.push('pb-8', 'sm:pb-14'); break;
                    case "xxl":classes.push('pb-12', 'sm:pb-18'); break;
                    default: classes.push('pb-4', 'sm:pb-6'); break;
                }
            }

            if (this.theContent.rounded) {
                switch (this.theContent.rounded) {
                    case "s":  classes.push('rounded'); break;
                    case "m":  classes.push('rounded-lg'); break;
                    case "l":  classes.push('rounded-xl'); break;
                }
            }
            return classes;
        },
        styleObj() {
            let styleObj = {}

            if (this.funnel.settings.fontFamily) {
                styleObj = { ...styleObj, fontFamily: this.funnel.settings.fontFamily }
            }

            if (this.element && this.element.content && this.element.content.style) {
                Object.keys(this.element.content.style).forEach((key) => {
                    if (this.element.content.style[key] !== null && this.element.content.style[key] !== undefined) {
                        styleObj = { ...styleObj, [key]: this.element.content.style[key] }
                    }
                })
            }

            return styleObj
        },
        theContent() {
            if (this.element && this.element.content && this.element.content.content) {
                return this.element.content.content;
            } else {
                return false;
            }
        },
    }
}
</script>

<style>
.bg-custom1-100,
.bg-custom1-200,
.bg-custom1-300,
.bg-custom1-400,
.bg-custom1-500,
.bg-custom1-600,
.bg-custom1-700,
.bg-custom1-800,
.bg-custom1-900 { background-color: v-bind(customBgColor1); }
.text-custom1-100,
.text-custom1-200,
.text-custom1-300,
.text-custom1-400,
.text-custom1-500,
.text-custom1-600,
.text-custom1-700,
.text-custom1-800,
.text-custom1-900 { color: v-bind(customColor1); }
</style>
