<template>
    <funnerix-wrap :element="element" :funnel="funnel">
        <div :class="!preview ? 'prose whitespace-pre-line mx-auto' : ''" v-if="theContent">
            <div class="max-w-7xl mx-auto" :class="`text-${theContent.align}`">
                <div
                    class="max-w-prose mx-auto lg:max-w-lg lg:ml-auto"
                    :class="itemClasses">
                    <funnerix-tiptap :editable="!disabled" v-if="preview" v-model="theContent.text" :preview="preview" :initialTabindex="initialTabindex" />
                    <div v-else v-html="textTransformed(theContent.text)" />
                </div>
            </div>
        </div>
    </funnerix-wrap>
</template>

<script>
import modulesMixin from "../modulesMixin";
export default {
    mixins: [ modulesMixin ],
    computed: {
        itemClasses() {
            let classes = [];
            switch (this.theContent.size) {
                case "xs": classes.push('text-xs'); break;
                case "s":  classes.push('text-md'); break;
                case "m":  classes.push('text-lg'); break;
                case "l":  classes.push('text-xl'); break;
                case "xl": classes.push('text-2xl'); break;
                default:  classes.push('text-lg'); break;
            }
            switch (this.theContent.align) {
                case "center": classes.push('text-center'); break;
                case "right": classes.push('text-right'); break;
                default: classes.push('text-left'); break;
            }
            return classes;
        },
    }
}
</script>

<style>

</style>
